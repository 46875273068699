import service from '@/utils/request'; // @Tags App
// @Summary 创建App
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.App true "创建App"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /app/createApp [post]

export var createApp = function createApp(data) {
  return service({
    url: "/app/createApp",
    method: 'post',
    data: data
  });
}; // @Tags App
// @Summary 删除App
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.App true "删除App"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /app/deleteApp [delete]

export var deleteApp = function deleteApp(data) {
  return service({
    url: "/app/deleteApp",
    method: 'delete',
    data: data
  });
}; // @Tags App
// @Summary 删除App
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除App"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /app/deleteApp [delete]

export var deleteAppByIds = function deleteAppByIds(data) {
  return service({
    url: "/app/deleteAppByIds",
    method: 'delete',
    data: data
  });
}; // @Tags App
// @Summary 更新App
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.App true "更新App"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /app/updateApp [put]

export var updateApp = function updateApp(data) {
  return service({
    url: "/app/updateApp",
    method: 'put',
    data: data
  });
}; // @Tags App
// @Summary 用id查询App
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.App true "用id查询App"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /app/findApp [get]

export var findApp = function findApp(params) {
  return service({
    url: "/app/findApp",
    method: 'get',
    params: params
  });
}; // @Tags App
// @Summary 分页获取App列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取App列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /app/getAppList [get]

export var getAppList = function getAppList(params) {
  return service({
    url: "/app/getAppList",
    method: 'get',
    params: params
  });
};